class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("auth.currentUser"));
    return user != null ? user.refreshToken : "";
  }
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("auth.currentUser"));
    return user != null ? user.data.key.token : "";
  }
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("auth.currentUser"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
  getUser() {
    return JSON.parse(localStorage.getItem("auth.currentUser"));
  }
  setUser(user) {
    localStorage.setItem("auth.currentUser", JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem("auth.currentUser");
    localStorage.removeItem("permit");
    window.location.href = "/login";
  }
}
export default new TokenService();
