export default {
  methods: {
    can(permissionName) {
      //   let Permission = window.localStorage.getItem("all_permission");
      let Permission = JSON.parse(localStorage.getItem("permit"));

      if (typeof Permission != "undefined") {
        // let a = Permission.indexOf(permissionName) > -1;
        return Permission.indexOf(permissionName) > -1;
      }
    },
  },
};
