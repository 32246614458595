/* eslint-disable no-console */
import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";
import router from "./router/index";
import store from "@/state/store";
import vco from "v-click-outside";
import "@/design/index.scss";
import Sparkline from "vue-sparklines";
import "@/common/axios";
import messages from "./localize";
import Geocoder from "@pderas/vue2-geocoder";
// import OneSignalVue from "onesignal-vue";
// Vue.use(OneSignalVue);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "id",
  fallbackLocale: "id",
  messages,
});

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: false,
  rtl: false,
});

import VueCurrencyFilter from "vue-currency-filter";

Vue.use(VueCurrencyFilter, [
  {
    symbol: "Rp.",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: true,
  },
  {
    name: "currency_2",
    symbol: "IDR ",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: true,
  },
  {
    name: "currency_3",
    symbol: "Rp.",
    thousandsSeparator: ",",
    fractionCount: 0,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: true,
  },
]);

import VueMoment from "vue-moment";
import moment from "moment";

Vue.use(VueMoment, { moment });

import Pusher from "pusher-js";
window.Pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: "ap1",
  encrypted: false,
});

import firebase from "firebase/app";
import "firebase/messaging";

firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
});

navigator.serviceWorker
  .register("firebase-messaging-sw.js")
  .then((registration) => {
    const messaging = firebase.messaging();
    messaging.useServiceWorker(registration);
  })
  .catch((err) => {
    console.log(err);
  });

import Permissions from "./mixinsi/Permission";
Vue.mixin(Permissions);

import setupInterceptors from "@/common/setupInterceptors";
setupInterceptors(store);

import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueSweetalert2);
Vue.use(require("vue-chartist"));
Vue.use(vco);
Vue.use(Sparkline);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_KEY,
    libraries: "places", // necessary for places input
  },
  installComponents: true,
});
Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: null, // e.g. 'en'
  defaultMode: "lat-lng", // or 'lat-lng'
  googleMapsApiKey: process.env.VUE_APP_GOOGLE_KEY,
});
Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
