import axios from "axios";
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  headers: {
    "Content-Type": "application/json",
    "X-timezone": "Asia/Jakarta",
    Accept: "application/json",
  },
});
export default instance;
